import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './css/styles.css'
import AboutPage from './pages/AboutPage';
import HomePage from './pages/HomePage';


function App() {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  return (
    <Router>
      <div className={` ${sidebarCollapsed ? 'sidebar-collapsed' : ''}`}>
        <nav className={`sidebar ${sidebarCollapsed ? 'collapsed' : ''}`} 
        aria-label="Main Navigation" role="navigation">
          <ul className='sidebar'>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
          </ul>
          <div className="sidebar-toggle" onClick={toggleSidebar} 
          onKeyDown={(e) => {
            if (e.key === 'Enter') {toggleSidebar();}}}
              role="button" tabIndex={0}
              aria-label={sidebarCollapsed ? 'Expand Sidebar' : 'Collapse Sidebar'}>
              <span aria-hidden="true">
                {sidebarCollapsed ? '►' : '◄'}
              </span>
          </div>
        </nav>
      </div>
        <main className="content">
          {/* Wrap Routes */}
          <Routes>
            {/* Define Routes */}
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
          </Routes>
        </main>
    </Router>
  );
}

export default App;
