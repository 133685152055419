import React from 'react';

function HomePage() {
    return (
        <div>
          <header>
          <h2 aria-label="Forgiveness" autoFocus tabIndex={1}>Forgiveness</h2>
          </header>
          <main>
            <p aria-label="Forgiveness">
              Lord God, I come to you as a sinner and I humbly repent for my sins. 
              I believe that Jesus is Lord, I believe that you raised him from the 
              dead. I would like Jesus to come into my life as my Lord and Savior. 
              I receive Jesus to take complete control of my life, 
              that I might live for him from this day forward. Thank you Lord Jesus for saving me 
              with your precious blood in Jesus Name Amen.
            </p>
          </main>
          <header>
          <h2 aria-label="Grace" tabIndex={2}>Grace</h2>
          </header>
          <main>
            <p aria-label="Grace">
              May the Grace of our Lord Jesus Christ, the Love of God, and the Fellowship of the Holy Spirit 
              be with us now and Forever More.
            </p>
          </main>
          <header>
          <h2 aria-label="Lord's Prayer" tabIndex={3}>The Lord's Prayer</h2>
          </header>
          <main>
            <p aria-label="Lord's Prayer">
              Our Father who hath in Heaven, Hallowed be thy name, your Kingdom comes, Your will be done on earth
              as it is in Heaven, give us this day our Daily Bread and Forgive us our Trespasses as we forgive those 
              who trespass against us, lead us not into tempations but deliver us from evil for thy is the kingdom, the Power and Glory, 
              Forever and Ever Amen. 
            </p>
          </main>
        </div>
      );
}

export default HomePage;
