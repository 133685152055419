import React from 'react';

function AboutPage() {
  return (
    <div>
      <h2>About Us</h2>
      <p>This is the About page content.</p>
    </div>
  );
}

export default AboutPage;
